import { graphql } from 'gatsby';
import React from 'react';

import { MediaItemProps } from '../../../atoms/Image/types';
import { getImage } from '../../../../utils/sanityTypes';
import HeroBase from '../../../organisms/Hero';

import { HeroBaseSectionProps } from './types';

const HeroBaseSection: React.VFC<HeroBaseSectionProps> = ({
  title,
  isPageTitle,
  subtitle,
  scrollButtonLabel,
  content: contentRichText,
  images,
  hasTextBgOverlay,
}) => (
  <HeroBase
    data={{
      hasTextBgOverlay,
      title: title ?? '',
      customTitleTag: isPageTitle ? 'h1' : 'h2',
      subtitle: subtitle ?? '',
      scrollButtonLabel: scrollButtonLabel ?? '',
      contentRichText: contentRichText ?? undefined,
      images: (images ?? []).map<MediaItemProps>((slide) => {
        const { mobileImage, image, _key: key } = slide ?? {};

        return {
          key: key ?? '',
          image: getImage(image),
          mobileImage: getImage(mobileImage),
        };
      }),
    }}
  />
);

export const fragment = graphql`
  fragment HeroBaseSectionFragment on SanityHeroBaseSection {
    _key
    _type
    title
    isPageTitle
    subtitle
    content: _rawContent(resolveReferences: { maxDepth: 10 })
    scrollButtonLabel
    hasTextBgOverlay
    images {
      _key
      image {
        ...ImageFragment
      }
      mobileImage {
        ...ImageFragment
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default HeroBaseSection;
